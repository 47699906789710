import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import _ from "lodash"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Swiper from "swiper"
import SwiperAnimation from "@cycjimmy/swiper-animation"
import styled from "styled-components"
import "swiper/css/swiper.min.css"

import SEO from "../components/seo"
import ArrowDiagonal from "../images/arrow-diagonal.svg"
import LeftChevron from "../images/left-chevron.svg"
import RightChevron from "../images/right-chevron.svg"
import Layout from "../layouts/default"

const Pages = ({ data }) => {
  const { edges } = data.pages
  const [pageSlug, setpageSlug] = useState("")

  const isBrowser = typeof window !== "undefined" && window
  let swiperAnimation

  if (typeof window !== "undefined") {
    swiperAnimation = new SwiperAnimation()
  }

  useEffect(() => {
    const pathName = isBrowser && window.location.pathname
    const pathNameLength = isBrowser && window.location.pathname.length
    const lastOccur = pathName.lastIndexOf("/")
    const modifiedSlug = pathName.substring(lastOccur + 1, pathNameLength)

    setpageSlug(modifiedSlug)

    new Swiper(".swiper-container", {
      loop: true,
      speed: 2300,
      mousewheel: true,
      effect: "fade",
      direction: "vertical",
      fadeEffect: { crossFade: true },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },

      navigation: {
        nextEl: ".slide-next",
        prevEl: ".slide-prev",
      },

      on: {
        init: function() {
          swiperAnimation.init(this).animate()
        },
        slideChange: function() {
          swiperAnimation.init(this).animate()
        },
      },
    })
  }, [pageSlug])

  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css"
        />
      </Helmet>
      {edges.map(({ node }) => {
        return (
          node.slug === pageSlug && (
            <React.Fragment key={node.id}>
              <SEO title={node.seo.title} description={node.seo.description} />
              <StyledPages className="swiper-container">
                <div className="swiper-wrapper">
                  {!_.isNil(node.content) &&
                    node.content.map((page, i) => {
                      return (
                        <article key={i} className="swiper-slide homepage">
                          <div
                            data-swiper-animation="animate__fadeInUp"
                            data-duration=".8s"
                            data-delay=".2s"
                            data-swiper-out-animation="animate__fadeOutDown"
                            data-out-duration="1s"
                            className="homepage-text"
                          >
                            <h1 className="homepage-text__title">
                              {page.title}
                            </h1>

                            <AniLink
                              cover
                              bg="#fff"
                              duration={2}
                              to={`/${node.slug}/${page.slug}`}
                              className="homepage-text__cta"
                            >
                              Scopri di più
                              <ArrowDiagonal />
                            </AniLink>
                          </div>

                          <AniLink
                            cover
                            bg="#fff"
                            duration={2}
                            data-swiper-animation="animate__fadeIn"
                            data-duration=".8s"
                            data-delay=".2s"
                            data-swiper-out-animation="animate__fadeOut"
                            data-out-duration="1s"
                            to={`/${node.slug}/${page.slug}`}
                            className="homepage-image"
                          >
                            <img
                              src={
                                page.albumCover
                                  ? page.albumCover.url
                                  : page.images[0].url
                              }
                              alt={page.title}
                            />
                          </AniLink>

                          <section className="homepage-footer">
                            <div
                              data-swiper-animation="animate__fadeInUp"
                              data-duration=".8s"
                              data-delay=".2s"
                              data-swiper-out-animation="animate__fadeOutDown"
                              data-out-duration="1s"
                              className="homepage-footer-left"
                            >
                              <span className="homepage-footer__category">
                                {node.pageTitle}
                              </span>
                              <AniLink
                                cover
                                bg="#fff"
                                duration={2}
                                to={`/${node.slug}/${page.slug}`}
                                className="homepage-footer__title"
                              >
                                {page.title}
                                <ArrowDiagonal />
                              </AniLink>
                            </div>
                            <div className="homepage-footer__pagination">
                              <button className="slide-prev">
                                <LeftChevron />
                                <span className="text">prev</span>
                              </button>
                              <button className="slide-next">
                                <RightChevron />
                                <span className="text">next</span>
                              </button>
                            </div>
                          </section>
                        </article>
                      )
                    })}
                </div>

                {/* {edges.map(({ node }) => {
                  return (
                    node.content.length > 1 && (
                      <div className="progress-bar">
                        <div className="swiper-pagination"></div>
                      </div>
                    )
                  )
                })} */}
              </StyledPages>
            </React.Fragment>
          )
        )
      })}
    </Layout>
  )
}

export const permPagesQuery = graphql`
  query PermPagesQuery {
    pages: allDatoCmsPagesCollection {
      edges {
        node {
          id
          slug
          pageTitle
          seo {
            title
            description
          }
          content {
            slug
            title
            description
            albumCover {
              url
            }
          }
        }
      }
    }
  }
`

export const StyledPages = styled.section`
  width: 100%;
  height: calc(100vh - (120px));
  margin: auto;
  margin-top: 100px;

  @media (min-width: 1024px) {
    width: calc(100% - 10rem);
    height: calc(100vh - (120px * 2));
    margin-top: 120px;
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .homepage-text {
    display: none;

    @media (min-width: 768px) {
      display: block;
      flex: 1 0 35%;
      margin-right: 2rem;
      font-family: "Poppins", sans-serif;
    }

    &__title {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 2px;

      @media (min-width: 1024px) {
        margin-top: 0;
      }
    }

    &__cta {
      padding-bottom: 0.3rem;
      font-size: 0.8rem;
      text-decoration: none;
      opacity: 0.6;
      cursor: pointer;

      svg {
        position: relative;
        top: 2px;
        width: 12px;
        height: 12px;
        margin-left: 0.3rem;
      }
    }
  }

  .homepage-image {
    flex: 1 0 100%;
    height: calc(100vh - (120px * 2));

    @media (min-width: 1024px) {
      flex-basis: 48%;
      height: 65vh;
    }

    img {
      width: 100%;
      height: 100%;
      margin-left: auto;
      object-fit: scale-down;
      object-position: center;

      @media (min-width: 1024px) {
        object-position: top right;
      }
    }
  }

  .homepage-footer {
    width: 100%;
    height: 145px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Poppins", sans-serif;

    position: fixed;
    bottom: 0;

    @media (min-width: 768px) {
      /* height: 100px; */
      height: 45px;

      justify-content: flex-end;
      /* display: none; */
    }

    &-left {
      width: 70%;

      @media (min-width: 768px) {
        width: auto;
        display: none;
      }
    }

    &__category {
      display: block;
      margin-bottom: 10px;
      color: #828282;
      font-size: 0.9rem;
      font-weight: 300;
      letter-spacing: 2px;
    }

    &__title {
      display: block;
      margin-right: 0.5rem;

      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 1.6;
      text-decoration: none;
      overflow-wrap: break-word;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 0.5rem;
      }
    }

    &__pagination {
      width: 80px;
      display: flex;
      justify-content: space-between;
      align-self: center;

      button {
        width: 18px;
        height: 18px;

        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        @media (min-width: 768px) {
          width: auto;
          height: auto;
        }

        svg {
          width: 100%;
          height: 100%;
          flex-shrink: 0;

          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      .text {
        display: none;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }
  }

  .progress-bar {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    pointer-events: none;
  }

  .swiper-pagination-fraction {
    width: 100%;
    position: fixed;
    bottom: 20px;
    left: 0;
    /* height: 8px; */
  }

  .swiper-pagination-progressbar-fill {
    background-color: #000;
  }
`

export default Pages
